// i18next-extract-mark-ns-start products-fearce-loads

import { FEARCE_NAV } from '@utils/constants';
import { graphql } from 'gatsby';
import React from 'react';

import Aside from '@components/Aside';
import AssetList from '@components/AssetList';
import Banner from '@components/Banner';
import Breadcrumbs, { Breadcrumb } from '@components/Breadcrumbs';
import PageSection from '@components/PageSection';
import Page from '@components/layout/Page';
import SEO from '@components/layout/SEO';
import { Link, Trans, useTranslation } from 'gatsby-plugin-react-i18next';

const ProductFearcePage = ({
	pageContext: {
		breadcrumb: { crumbs },
	},
	data
}: {
	pageContext: {
		breadcrumb: { crumbs: Breadcrumb[] };
	};
	data: any;
}) => {
	const { t } = useTranslation(); return (
		<Page>
			<SEO
				title={t("seo_title")}
				description={t("seo_description")}
			/>

			<Banner
				title={t("title")}
				image={{
					src: data.banner.childImageSharp.gatsbyImageData,
					alt: '',
				}}
				size="narrow"
			/>

			<Breadcrumbs crumbs={crumbs} />

			<PageSection
				className="section__content section__content--dual"
				layout="none">
				<div className="col xl7 m12 m-spacer page__content page__content--dual">
					<Trans i18nKey="content">
						<p>
							FEARCE incorporates a range of features to aid engineers
							in applying loads to finite element (FE) structures and
							assemblies. Using the method of identifying regions to
							be loaded, by geometry rather than node identifiers, a
							user only has to select a named area and choose what
							type of load is required. FEARCE will automatically
							distribute pressure across a surface, ensuring that
							individual nodes are supplied with the correct force
							components.
						</p>
						<p>
							FEARCE contains a number of bearing models that apply
							suitable pressure profiles across load areas. The user
							simply identifies the required load area by name,
							defines what type of bearing is used and supplies the
							required bearing force vector. FEARCE will then adjust
							the nodal forces so that the resulting distributed load
							is correct for the bearing type.
						</p>
						<p>
							Loads can also be mapped from external models or files.
							This is useful when incorporating results from one
							analysis as boundary conditions on a separate model for
							a second analysis. Examples include mapping thermal
							results onto a model as temperature loads (including
							models from computational fluid dynamics (CFD) conjugate
							analyses) and mapping displacements from a complete
							assembly onto a local model.
						</p>
						<h2>Key features</h2>
						<ul>
							<li>
								Pre-processing time reduced through automation of
								load application
							</li>
							<li>
								Increased accuracy as loads are defined explicitly
								and any conversions are carried out by FEARCE
							</li>
							<li>
								Load regions are defined as named areas rather than
								relying on individual node IDs, allowing component
								models to be easily replaced
							</li>
							<li>
								Bearing models allow accurate profiles to be added
								automatically regardless of mesh distribution
							</li>
							<li>
								Mapping of loads between models allows a user to
								quickly set up local models for detailed assessment
							</li>
						</ul>
					</Trans>
				</div>
				<Aside>
					<Aside.Widget
						title={t("_Applications")}
						className="widget__sibling__pages">
						<AssetList>
							{FEARCE_NAV.map((item, i) => (
								<Link
									key={i}
									to={item.value}
									className="assets__link"
									activeClassName="active">
									{t(item.labelkey) /* i18next-extract-disable-line */}
								</Link>
							))}
						</AssetList>
					</Aside.Widget>
				</Aside>
			</PageSection>
		</Page>
	);
};

export default ProductFearcePage;

export const pageQuery = graphql`
query ($language: String!) {
	locales: allLocale(
		filter: { ns: { in: ["products-fearce-loads", "_common"] }, language: { eq: $language } }
	) {
		edges {
			node {
				ns
				data
				language
			}
		}
	}
	banner: file(relativePath: { eq: "content/fearce-hero.jpg" }) {
		...imageBreaker
	}
}
`;